import React from "react";
import { graphql } from "gatsby";
import Image from "../components/Image";

import Layout from "../components/Layout";
import PortableText from "../components/PortableText";

export const query = graphql`
  {
    sanityHomePage {
      logo {
        asset {
          id
          originalFilename
          url
        }
        altText
      }
      coverImage {
        asset {
          id
          originalFilename
          url
        }
        altText
      }
      _rawBody
    }
  }
`;

export default function Home({
  data: {
    sanityHomePage: { logo, coverImage, _rawBody }
  }
}) {
  return (
    <Layout title="Home" page="home" url="/">
      <h1 className="home___logo">{logo.asset && <Image image={logo} />}</h1>
      {coverImage.asset && <Image image={coverImage} className="cover-image" />}
      {_rawBody && <PortableText blocks={_rawBody} />}
    </Layout>
  );
}
